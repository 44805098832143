export const REST_METHOD = {
	GET: 'get',
	POST: 'post',
	PUT: 'put'
};

const config = {
	url: 'https://test.api.bookall.rs/api'
};

export default config;
